import React from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';
import { useTranslate } from 'src/providers';
import styles from './PhoneNumber.module.css';

type PhoneNumberProps = {
  name: string;
  phoneNumber?: string;
  textColour?: 'black' | 'blue';
};

export const PhoneNumber = ({
  name,
  phoneNumber,
  textColour = 'blue',
}: PhoneNumberProps) => {
  const translate = useTranslate();

  return phoneNumber ? (
    /* Needed to not trigger card opening on click, but only phone number call */
    <div role="presentation" onClick={(e) => e.stopPropagation()}>
      <Track eventLabel="phone number">
        <a
          href={`tel:${phoneNumber}`}
          aria-label={
            translate('global.retailer.phoneNumberAriaLabel', {
              retailerName: name,
              phoneNumber,
            }) || `${name} Phone: ${phoneNumber}`
          }
          className={`${styles.link} ${textColour === 'blue' ? 'text-accent-blue' : 'text-primary'} font-16 flex gap-8 items-center`}
          data-testid="dealer:phoneNumber"
        >
          <Icon icon="phone" size={16} />
          {phoneNumber}
        </a>
      </Track>
    </div>
  ) : null;
};
