import { useEffect, useState } from 'react';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Retailer } from 'src/types/retailer';
import { getMapLinkQuery } from 'src/utils/getMapLinkQuery';
import { formatDistance } from 'src/utils/formatDistance';
import { isEqualRetailer } from 'src/utils/isEqualRetailer';
import { useStore } from './useStore';

type RetailerInfo = {
  distance?: string;
  addressHref: string;
  addressLines: string[];
  isSelectedRetailer: boolean;
};

export function useFormattedRetailerCardInfo(retailer: Retailer): RetailerInfo {
  const [isSelectedRetailer, setIsSelectedRetailer] = useState(false);
  const { address: currentAddress, selectedRetailer } = useStore();
  const {
    distanceFromPointMiles,
    distanceFromPointKm,
    name,
    addressLine1,
    addressLine2,
    mapLocation,
    coordinatesMapLink,
    longitude,
    latitude,
  } = retailer;

  useEffect(() => {
    if (isEqualRetailer(retailer, selectedRetailer)) {
      setIsSelectedRetailer(true);
    } else {
      setIsSelectedRetailer(false);
    }
  }, [retailer, selectedRetailer]);

  const mapLinkQuery = getMapLinkQuery({
    coordinatesMapLink,
    name,
    addressLine1,
    addressLine2,
    longitude,
    latitude,
  });

  const { locale, roadLengthUnit } = useCurrentMarketSite();
  const activeUserAddress = !!(
    currentAddress?.coords?.latitude || currentAddress?.place_id
  );
  const distance =
    roadLengthUnit === 'mile' ? distanceFromPointMiles : distanceFromPointKm;
  const formattedDistance = activeUserAddress
    ? formatDistance(distance, locale, roadLengthUnit)
    : undefined;

  const addressLines = [
    ...addressLine1.split('\n'),
    ...addressLine2.split('\n'),
  ];

  return {
    isSelectedRetailer,
    distance: formattedDistance,
    addressHref: mapLocation || `https://maps.google.com/?q=${mapLinkQuery}`,
    addressLines,
  };
}
