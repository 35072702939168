import React, { memo } from 'react';
import { RetailerNameDistance } from '@vcc-package/retailer-selector';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { Retailer } from 'src/types/retailer';
import { getDealerId } from 'src/utils/volvoTrackingHelpers';
import { getValidSiteSlug } from 'src/utils/getValidSiteSlug';
import { useFormattedRetailerCardInfo } from 'src/hooks/useFormattedRetailerCardInfo';
import { getRetailerLinks } from 'src/utils/getRetailerLinks';
import RetailerAddress from '../RetailerAddress';
import { RetailerLinksGroup } from '../../redesign/RetailerLinksGroup';
import CapabilityPills from '../CapabilityPills';
import CapabilitySelection from './CapabilitySelection';

type CardContentProps = { retailer: Retailer };

const CardContent = ({ retailer }: CardContentProps) => {
  const { siteSlug } = useCurrentMarketSite();
  const { name } = retailer;

  const { distance, addressHref, addressLines } =
    useFormattedRetailerCardInfo(retailer);
  const validSiteSlug = getValidSiteSlug(siteSlug);
  const redesign = true;
  const links = getRetailerLinks(retailer, validSiteSlug, redesign);

  return (
    <div data-testid="dealer:retailerContainer">
      <div className="flex-col gap-24">
        <RetailerNameDistance name={name} distance={distance} />
        <RetailerAddress
          name={name}
          url={addressHref}
          addressLines={addressLines}
        />
        <CapabilityPills capabilities={retailer.capabilities} />
        <CapabilitySelection {...retailer} />
      </div>
      {links.length > 0 && (
        <>
          <div className="border-ornament border-b h-24" />
          <RetailerLinksGroup
            dealerId={getDealerId(retailer)}
            dealerInfo={name}
            links={links}
          />
        </>
      )}
    </div>
  );
};
export default memo(CardContent);
