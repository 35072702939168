import React, { useCallback } from 'react';
import { ExtendCSS, Flex, Text } from 'vcc-ui';
import { updateMapPanBoundsZoom } from 'src/utils/mapUtils';
import { useClusters } from './useClusters';

type Clusters = ReturnType<typeof useClusters>;
export const Cluster = ({
  map,
  cluster,
}: {
  lat: number;
  lng: number;
  map?: google.maps.Map | null;
  cluster: Clusters[number];
}) => {
  const handleClick = useCallback(() => {
    const lng = cluster.geometry.coordinates?.[0];
    const lat = cluster.geometry.coordinates?.[1];
    const zoom = cluster.expansionZoom;

    updateMapPanBoundsZoom(map, { lat, lng, zoom });
  }, [map, cluster]);
  return (
    <Flex
      onClick={handleClick}
      extend={clusterCSS}
      data-testid="dealer:mapClusterPin"
    >
      <Text subStyle="emphasis" extend={clusterTextCSS}>
        {cluster?.properties?.point_count || ''}
      </Text>
    </Flex>
  );
};
const clusterCSS: ExtendCSS = ({ theme: { color } }) => ({
  height: 32,
  width: 32,
  background: color.brand.primary,
  borderRadius: '100%',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});
const clusterTextCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.inverted,
});
