import { Retailer } from 'src/types/retailer';

export const isEqualRetailer = (
  retailer1: Retailer | null | undefined,
  retailer2: Retailer | null | undefined,
) => {
  return (
    retailer1?.latitude === retailer2?.latitude &&
    retailer1?.longitude === retailer2?.longitude
  );
};
