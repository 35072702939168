import { Track } from '@volvo-cars/tracking';
import { useTranslate } from 'src/providers';
import styles from './RetailerAddress.module.css';

type RetailerAddressProps = {
  name: string;
  url?: string;
  addressLines: string[];
};
const RetailerAddress = ({ name, url, addressLines }: RetailerAddressProps) => {
  const translate = useTranslate();
  const addresses = (
    <>
      {addressLines.map((line) => (
        <span key={line}>
          {line} <br />
        </span>
      ))}
    </>
  );

  return (
    <>
      {addressLines.length > 0 && (
        <Track eventLabel="driving instructions">
          <div
            className={`${styles.address} overflow-hidden whitespace-normal`}
          >
            {url ? (
              <a
                className={`${styles.addressLink} link-plain w-full block`}
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                aria-label={
                  translate('global.retailer.getDirectionsAriaLabel', {
                    retailerName: name,
                  }) || `Get directions ${name}`
                }
              >
                {addresses}
              </a>
            ) : (
              <p>{addresses}</p>
            )}
          </div>
        </Track>
      )}
    </>
  );
};

export default RetailerAddress;
