import { useState } from 'react';
import { Icon } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';
import { useDialog } from '@volvo-cars/react-headless';
import { useRetailers, useTranslate } from 'src/hooks';
import FilterSheet from './FilterSheet';

const FilterButton = () => {
  const [reset, setReset] = useState(false);
  const onToggle = () => {
    setReset((prev) => !prev);
  };
  const { dialogProps, showDialog, closeDialog } = useDialog({ onToggle });
  const { activeCapabilityFilters } = useRetailers();
  const translate = useTranslate();
  return (
    <>
      <Track eventLabel="open-filters">
        <button
          type="button"
          className="button-filled p-16 gap-8"
          aria-label={
            translate('search.form.filterButtonAriaLabel') || 'Filter'
          }
          onClick={showDialog}
        >
          <Icon icon="filter" filled color="always-white" size={16} alt="" />
          {!!activeCapabilityFilters.length && activeCapabilityFilters.length}
        </button>
      </Track>
      <FilterSheet
        onClose={closeDialog}
        dialogProps={dialogProps}
        resetToggle={reset}
      />
    </>
  );
};

export default FilterButton;
