import { IconButton } from '@volvo-cars/react-icons';
import { useTranslate } from 'src/hooks';

type CardHeaderProps = {
  onClose: () => void;
};

// Designed to work both in Dialog and standalone
const CardHeader = ({ onClose }: CardHeaderProps) => {
  const translate = useTranslate();
  return (
    <>
      <div slot="back">
        <IconButton
          aria-label={translate('search.form.back') || 'Back'}
          bleed
          icon="chevron-back"
          onClick={onClose}
          variant="clear"
        />
      </div>
      <div slot="close">
        <IconButton
          aria-label={translate('search.form.close') || 'Close'}
          bleed
          icon="x"
          onClick={onClose}
          variant="clear"
        />
      </div>
    </>
  );
};
export default CardHeader;
