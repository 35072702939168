import type { SiteSlug } from '@volvo-cars/market-sites';
import { ENABLED_SELECTOR_MARKET_SITES } from 'src/constants/selectorFeatureConstants';
import { seoKeywords } from 'src/constants/seoKeywords';

export function getAvailableMarketSites(featureVariation?: string): SiteSlug[] {
  let enabledMarketSites = getAvailable(featureVariation);
  if (process.env.DEPLOY_ENV === 'prod') {
    enabledMarketSites = enabledMarketSites.filter(
      (siteSlug) => siteSlug !== 'master',
    );
  }
  return enabledMarketSites;
}

const getAvailable = (featureVariation?: string): SiteSlug[] => {
  switch (featureVariation) {
    case 'selector':
      return ENABLED_SELECTOR_MARKET_SITES;

    default:
      return Object.keys(seoKeywords) as SiteSlug[];
  }
};
