import React, { useCallback } from 'react';
import Link from 'next/link';
import { Icon } from '@volvo-cars/react-icons';
import { Lead, useTracker } from '@volvo-cars/tracking';
import { useTranslate } from 'src/hooks';
import { RetailerLink } from 'src/utils/getRetailerLinks';
import { TRACKING_CATEGORY } from '../../../constants/tracking';

export const RetailerLinksGroup = ({
  dealerId,
  dealerInfo,
  links,
}: Lead & { links: RetailerLink[] }) => {
  const translate = useTranslate();
  const track = useTracker();

  const sendGtmClickEvent = useCallback(
    (eventLabel: string) => {
      track.customEvent({
        dealerId,
        dealerInfo,
        eventAction: 'link|click',
        eventCategory: TRACKING_CATEGORY,
        eventLabel,
      });
    },
    [dealerId, dealerInfo, track],
  );
  return (
    <div className="flex-col gap-x-24 mt-8 w-full">
      {links.map(({ url, translationKey, eventLabel, defaultLabel }) => (
        <Link
          href={url}
          key={eventLabel}
          onClick={() => sendGtmClickEvent(eventLabel)}
          target="_blank"
          className="py-16 text-accent-blue hover:text-primary"
        >
          <div className="flex items-center justify-between">
            <p className="font-medium">
              {translate(translationKey) || defaultLabel}
            </p>
            <Icon icon="chevron-forward" size={12} color="accent-blue" />
          </div>
        </Link>
      ))}
    </div>
  );
};
