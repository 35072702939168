import React, { ComponentType, KeyboardEvent, memo } from 'react';
import { TextInput } from '@volvo-cars/react-forms';
import { useTracker } from '@volvo-cars/tracking';
import { IconButton } from '@volvo-cars/react-icons';
import { getIconButtonPropsFromDeprecatedIconType } from '@volvo-cars/react-icons/compat';
import { useTranslate } from 'src/hooks';
import { TRACKING_CATEGORY } from 'src/constants/tracking';

const TextInputWithIcon: ComponentType<React.PropsWithChildren<any>> = ({
  iconType,
  inputRef,
  handleSubmit,
  onFocus,
  isLoading,
  ...props
}) => {
  const track = useTracker();
  const translate = useTranslate();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      track.customEvent({
        eventAction: 'input field|click',
        eventCategory: TRACKING_CATEGORY,
        eventLabel: `search: ${props.value}`,
      });
      return handleSubmit();
    }
  };

  return (
    <TextInput
      contentAfter={
        isLoading ? (
          <progress aria-label="Loading" className="spinner w-16" />
        ) : (
          <IconButton
            {...getIconButtonPropsFromDeprecatedIconType(iconType)}
            onClick={handleSubmit}
            aria-label={
              translate('search.form.searchButtonAriaLabel') ||
              'Search and continue to the filtered results'
            }
            variant="clear"
            data-testid="dealer:searchButton"
          />
        )
      }
      id="address-input"
      htmlFor="address-input"
      ref={inputRef}
      type="search"
      role="search"
      {...props}
      onKeyDown={handleKeyDown}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
        track.customEvent({
          eventAction: 'input field|click',
          eventCategory: TRACKING_CATEGORY,
          eventLabel: `search: ${props.value}`,
        });
        onFocus(e);
      }}
      data-testid="dealer:search"
    />
  );
};
export default memo(TextInputWithIcon);
