import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import memoize from 'lodash/memoize';
import { useKeyPress } from '@volvo-cars/react-utils';
import { Icon } from '@volvo-cars/react-icons';
import {
  useAddressAutocomplete,
  useRetailers,
  useSearchInput,
  useSelectorSettings,
  useStore,
  useTranslate,
  useUserLocation,
} from 'src/hooks';
import { useFeatureFlags } from 'src/hooks/useFeatureFlags';
import { StoreContextValue } from 'src/providers';
import { translateCapability } from 'src/utils/translateCapability';
import { usePointerScroll } from 'src/hooks/usePointerScroll';
import SuggestionsBox from './redesign/SuggestionsBox';
import TextInputWithIcon from './redesign/TextInputWithIcon';
import FilterButton from '../FilterButton';
import { NoResultText } from './redesign/NoResultsText';

const SearchBlock = () => {
  const { dispatch, address, suggestionsVisible } = useStore();
  const { retailers, activeCapabilityFilters, availableCapabilityFilters } =
    useRetailers();
  const { useSelector } = useSelectorSettings();
  const { input, inputRef, setInput } = useSearchInput();
  const { useZipCodeSearch } = useFeatureFlags();
  const [showNoResultsText, setShowNoResultsText] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  usePointerScroll(scrollRef);
  const [invalidZipCodeInput, setInvalidZipCodeInput] = useState(false);
  const { isLoading } = useRetailers();

  const {
    handleClick: handleUseMyLocationClick,
    isLoading: isUserLocationLoading,
  } = useUserLocation();
  const translate = useTranslate();

  const [submitting, setSubmitting] = useState(false);

  const { data: predictions, requestSearchInput: googleRequestSearchInput } =
    useAddressAutocomplete(
      input?.value,
      {
        debounceTime: 500,
        skip: input?.skipFetch,
        resetPredictions: input?.resetPredictions,
      },
      useZipCodeSearch,
    );
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const predictionsLength = predictions?.length;
  useEffect(() => {
    if (!predictionsLength) return;
    if (highlightIndex > predictionsLength - 1) {
      setHighlightIndex(-1);
    }
    if (highlightIndex < -1) {
      setHighlightIndex(predictionsLength - 1);
    }
  }, [highlightIndex, predictionsLength]);
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInput({
        value,
        skipFetch: false,
        resetPredictions: false,
      });

      if (useZipCodeSearch) {
        // Regular expression to allow only numbers
        if (!/^\d*$/.test(value)) {
          setInvalidZipCodeInput(true);
        } else {
          setInvalidZipCodeInput(false);
        }
      }
    },
    [setInput, useZipCodeSearch],
  );
  const blurInput = useCallback(() => {
    inputRef?.current && inputRef?.current.blur();
    setHighlightIndex(-1);
  }, [inputRef]);
  useKeyPress('Escape', blurInput);
  const handlePredictionSelect = useCallback(
    (prediction: StoreContextValue['address']) => {
      dispatch({ type: 'SET_ADDRESS', payload: prediction });
      setInput({
        value: prediction?.description || '',
        skipFetch: false,
        resetPredictions: false,
      });
      blurInput();
    },
    [blurInput, dispatch, setInput],
  );

  const handleInputSubmit = useCallback(() => {
    setSubmitting(false);
    if (!inputRef?.current?.value.trim().length) {
      inputRef?.current && inputRef?.current.focus();
      return;
    }
    if (!predictionsLength || !predictions || !googleRequestSearchInput) {
      return;
    }

    const predictionSelected =
      highlightIndex >= 0 && highlightIndex <= predictionsLength - 1;
    if (predictionSelected) {
      handlePredictionSelect(predictions[highlightIndex]);
    }

    const submitSearchInputFormatted = inputRef?.current?.value.replace(
      /[!.,;>'"()]/g,
      '',
    );
    const googleRequestSearchInputFormatted = googleRequestSearchInput.replace(
      /[!.,;>'"()]/g,
      '',
    );
    if (googleRequestSearchInputFormatted === submitSearchInputFormatted) {
      handlePredictionSelect(predictions[0]);
    } else {
      setSubmitting(true);
    }
  }, [
    inputRef,
    predictionsLength,
    predictions,
    googleRequestSearchInput,
    highlightIndex,
    handlePredictionSelect,
  ]);

  useEffect(() => {
    if (submitting) {
      handleInputSubmit();
    }
  }, [handleInputSubmit, submitting, googleRequestSearchInput]);

  const isUseMyLocation = address?.coords?.longitude;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createFocusAndBlurHandler = useCallback(
    memoize(
      (
        nextInputValue: string,
        skipFetch: boolean,
        suggestionVisible: boolean,
      ) => {
        return () => {
          isUseMyLocation &&
            !predictionsLength &&
            setInput({
              value: nextInputValue,
              skipFetch,
              resetPredictions: false,
            });

          dispatch({
            type: 'SET_SUGGESTIONS_VISIBLE',
            payload: suggestionVisible,
          });
        };
      },
      (...args) => args.join(),
    ),
    [dispatch, predictionsLength, isUseMyLocation, setInput],
  );
  return (
    <>
      <h1 className="font-20 font-medium">
        {translate('global.general.header') || 'Find a Volvo retailer'}
      </h1>
      <div className="flex items-center gap-16">
        <div className="w-full">
          <TextInputWithIcon
            iconType="search"
            label={
              useZipCodeSearch
                ? `${translate('search.form.zipInputPlaceholder') || 'Zip code'}`
                : `${translate('search.form.inputPlaceholder') || 'Search'}`
            }
            value={input.value}
            inputRef={inputRef}
            onChange={handleInputChange}
            handleSubmit={handleInputSubmit}
            autoComplete="off"
            onFocus={createFocusAndBlurHandler('', false, true)}
            onBlur={createFocusAndBlurHandler(
              translate('search.form.myLocation') || 'My location',
              true,
              false,
            )}
            inputMode={useZipCodeSearch ? 'number' : 'search'}
            errorMessage={
              invalidZipCodeInput && 'Invalid input. Use numbers only'
            }
            isLoading={isLoading || isUserLocationLoading}
          />
        </div>
        {!useSelector && (
          // Because the errorMessage increase the height of the TextInputWithIcon
          <div className={`${invalidZipCodeInput && 'pb-24'} until-md:hidden`}>
            <FilterButton />
          </div>
        )}
      </div>
      <SuggestionsBox
        setInput={setInput}
        suggestionsVisible={suggestionsVisible}
        handlePredictionSelect={handlePredictionSelect}
        predictions={predictions}
        highlightIndex={highlightIndex}
      />
      <div className="flex gap-8 items-center">
        <Icon icon="map-pin" size={24} />
        <button
          type="button"
          className="link-inline"
          onClick={handleUseMyLocationClick}
        >
          {translate('search.form.useMyLocationButtonLabel')}
        </button>
        {!useSelector && (
          <div className="md:hidden ml-auto">
            <FilterButton />
          </div>
        )}
      </div>
      <div
        /* min-h-24 instead of min-h-fit due to safari issue to compute overflow + flexbox height */
        className="flex gap-8 overflow-x-auto -mx-24 pl-24 scrollbar-none min-h-24 dl-select-none"
        ref={scrollRef}
      >
        {!activeCapabilityFilters.length ||
        activeCapabilityFilters.length === availableCapabilityFilters.length ? (
          <div className="micro rounded-full py-2 px-8 w-fit border-ring whitespace-nowrap bg-always-white">
            {translate('global.retailer.showingAll') || 'Showing all'}
          </div>
        ) : (
          activeCapabilityFilters
            .sort(
              (a, b) =>
                availableCapabilityFilters.indexOf(a) -
                availableCapabilityFilters.indexOf(b),
            )
            .map((capability) => (
              <div
                key={capability}
                className="micro rounded-full py-2 px-8 w-fit border-ring whitespace-nowrap bg-always-white"
              >
                {translateCapability(capability, translate)}{' '}
              </div>
            ))
        )}
      </div>
      {showNoResultsText && !isUserLocationLoading && !retailers?.length && (
        <NoResultText
          handleLocationClick={handleUseMyLocationClick}
          handleClose={() => setShowNoResultsText(false)}
        />
      )}
    </>
  );
};

export default SearchBlock;
