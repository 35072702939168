import React from 'react';
import { useTranslate } from 'src/hooks';

type UserMyLocationProps = {
  onClick?: () => void;
};

export const UseMyLocation = ({
  onClick,
}: UserMyLocationProps): JSX.Element => {
  const translate = useTranslate();
  return (
    <button
      type="button"
      data-testid="useMyLocation"
      className="button-text my-8"
      onClick={onClick}
      onMouseDown={(event: any) => event.preventDefault()}
    >
      {translate('search.form.useMyLocationButtonLabel')}
    </button>
  );
};
