import { useTranslate } from 'src/providers';
import { Capability } from 'src/types/retailerCapabilities';
import { translateCapability } from 'src/utils/translateCapability';

type CapabilityPillsProps = { capabilities: Capability[] };

const CapabilityPills = ({ capabilities }: CapabilityPillsProps) => {
  const translate = useTranslate();
  const redesignCapabilities = capabilities.filter((cap) => cap !== 'selekt');
  /* TODO When going live - selekt is temporary, should be removed from capabilites */

  return (
    <div className="flex gap-8 flex-wrap">
      {redesignCapabilities.map((capability) => (
        <div
          key={capability}
          data-testid={`dealer:${capability}-pill`}
          className="micro rounded-full py-2 px-8 w-fit border-ring whitespace-nowrap"
        >
          {translateCapability(capability, translate)}{' '}
        </div>
      ))}
    </div>
  );
};

export default CapabilityPills;
