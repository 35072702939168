import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Block, Checkbox, ExtendCSS, Text } from 'vcc-ui';
import { useTracker } from '@volvo-cars/tracking';
import { TRACKING_CATEGORY } from 'src/constants/tracking';

export const CheckboxWithLabel = ({
  label,
  onChange,
  testid,
}: {
  label: string;
  onChange?: any;
  testid?: string;
}) => {
  const [checked, setChecked] = useState(true);
  const didMount = useRef(false);
  const track = useTracker();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    track.customEvent({
      eventAction: `filter|${e.target.checked ? 'select' : 'deselect'}`,
      eventCategory: TRACKING_CATEGORY,
      eventLabel: label,
    });
  };
  useEffect(() => {
    if (didMount.current) {
      onChange && onChange(checked);
    } else {
      didMount.current = true;
    }
  }, [onChange, checked]);
  return (
    <Block extend={checkboxContainerCSS}>
      <Checkbox
        onClick={() => setChecked((checked) => !checked)}
        checked={checked}
        onChange={handleChange}
        data-testid={testid}
        id={`${label}-checkbox`}
        label={
          <Text extend={checkboxLabelCSS} data-testid="dealer:checkboxLabel">
            {label}
          </Text>
        }
      />
    </Block>
  );
};
const checkboxContainerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: 5 * baselineGrid,
});
const checkboxLabelCSS: ExtendCSS = {
  cursor: 'pointer',
};
