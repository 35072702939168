export const topbarHeightUntilM = 48;
export const topbarHeightFromM = 64;
export const topbarHeight = 'var(--sitenav-topbar-height)';

export const defaultTrackingCategory = 'nav bar';
export const carsMenuTrackingCategory = 'our cars';
export const sideNavTrackingCategory = 'menu';
export const headerTrackingCategory = `${defaultTrackingCategory} | header`;
export const subHeaderTrackingCategory = `${defaultTrackingCategory} | sub-header`;

export const springConfig = {
  clamp: true,
  friction: 14,
};

export const zIndexDesktopCarsMenu = 1000;
export const zIndexSideNavigation = 1001;
export const zIndexBackdrop = 999;
export const zIndexTopBarWrapper = 999;

export const topbarTransition = (property: string) =>
  `${property} 300ms ease-in-out`;

export const SITE_NAV_ROOT = 'vcc-site-nav';
export const SITE_NAV_TOPBAR_ID = 'sitenav-topbar';
export const VISIBILITY_CHANGE_EVENT = 'sitenav:visibilitychange';
export const SHADOW_CONTAINER = 'vcc-site-nav-shadow-container';
export const ROOT_ID = 'site-navigation';
export const CONTROLLER_EXPOSURE_EVENT_NAME =
  '__SiteNavigationControllerExposed__';
