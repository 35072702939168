import { useEffect } from 'react';
import { useDialog } from '@volvo-cars/react-headless';
import { useBreakpoints } from '@vcc-www/hooks';
import { useStore } from 'src/hooks';
import SearchBlock from './SearchBlock';
import styles from './SearchPositioned.module.css';
import ExpandedCard, { ExpandedCardDialog } from '../retailerCard/ExpandedCard';
import SearchFrame from './SearchFrame';

type SearchPositionedProps = React.PropsWithChildren<{
  view: 'map' | 'list';
  scrollRef: React.RefObject<HTMLDivElement>;
}>;

export const SearchBlockWidth = 420;

const SearchPositioned = ({
  view,
  children,
  scrollRef,
}: SearchPositionedProps) => {
  // Needed to toggle status of expanded view modal on mobile / non mobile view.
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilL, fromL } = useBreakpoints();
  const {
    expandedCardVisible,
    selectedRetailer,
    retailersListVisible,
    expandedCardEnabled,
    dispatch,
  } = useStore();
  const onDialogToggle = ({ open }: { open: boolean }) => {
    if (!open) closeExpandedCard();
  };
  const { dialogProps, showDialog, closeDialog } = useDialog({
    onToggle: onDialogToggle,
  });

  useEffect(() => {
    const mapElement = document.querySelector('[aria-roledescription="map"]');

    if (!expandedCardEnabled) return;
    if (expandedCardVisible && untilL) {
      // This is fix for avoiding autofocus on the dialog backbutton. Because the map is in focus, it triggers a safaribug that is fixed in dialogcode, see https://github.com/volvo-cars/design-system-web/blob/master/packages/react-headless/src/dialog.ts#L221
      // You can still get the focusring when tabbing, because the pin is in focus before opening the dialog and not the map
      mapElement instanceof HTMLElement &&
        mapElement?.matches(':focus') &&
        mapElement.blur();
      mapElement instanceof HTMLElement &&
        mapElement?.matches(':focus-visible') &&
        mapElement.blur();
      showDialog();
    } else {
      mapElement?.setAttribute('tabindex', '0');
      closeDialog();
    }
  }, [
    expandedCardVisible,
    showDialog,
    closeDialog,
    untilL,
    expandedCardEnabled,
  ]);

  const closeExpandedCard = () => {
    dispatch({ type: 'SET_EXPANDED_CARD_VISIBLE', payload: false });
  };

  return (
    <>
      <div
        className={`${styles.searchWrapper} ${view === 'map' ? styles.searchWrapperMap : 'md:mt-24'} flex-col dl-z-1 w-full lg:min-h-0 mx-auto lg:m-auto sticky top-0`}
        style={{ '--search-block-width': `${SearchBlockWidth}px` }}
      >
        <SearchFrame>
          {expandedCardVisible &&
          selectedRetailer &&
          expandedCardEnabled &&
          fromL ? (
            <ExpandedCard
              onClose={closeExpandedCard}
              retailer={selectedRetailer}
            />
          ) : (
            <>
              <SearchBlock />
              {!!children && (
                <div
                  className={`${!retailersListVisible && 'hidden'} overflow-y-auto scrollbar-none -mb-24 lg:pb-24 -mx-8 px-8 -mt-4 pt-4 `} // Classes required to show full focus frame
                  ref={scrollRef}
                >
                  <div className="until-lg:hidden">{children}</div>
                </div>
              )}
            </>
          )}
        </SearchFrame>
        {view === 'list' && (
          <div className="flex-grow lg:hidden py-24 overflow-auto scrollbar-none">
            {children}
          </div>
        )}
      </div>
      <ExpandedCardDialog
        dialogProps={dialogProps}
        retailer={selectedRetailer}
        onClose={closeExpandedCard}
      />
    </>
  );
};

export default SearchPositioned;
