import { useEffect } from 'react';

export const usePointerScroll = (ref: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    const scrollElement = ref.current;
    if (!scrollElement) return;

    const dragStart = (ev: PointerEvent) => {
      scrollElement.setPointerCapture(ev.pointerId);
    };

    const dragEnd = (ev: PointerEvent) => {
      scrollElement.releasePointerCapture(ev.pointerId);
    };

    const drag = (ev: PointerEvent) => {
      if (scrollElement.hasPointerCapture(ev.pointerId)) {
        scrollElement.scrollLeft -= ev.movementX;
      }
    };

    scrollElement.addEventListener('pointerdown', dragStart);
    scrollElement.addEventListener('pointerup', dragEnd);
    scrollElement.addEventListener('pointermove', drag);

    return () => {
      scrollElement.removeEventListener('pointerdown', dragStart);
      scrollElement.removeEventListener('pointerup', dragEnd);
      scrollElement.removeEventListener('pointermove', drag);
    };
  }, [ref]);
};
