import { SearchBlockWidth } from 'src/features/dealer-picker/search/SearchPositioned';

type Timer = ReturnType<typeof setTimeout>;

type MapConfigType = {
  lat?: number;
  lng?: number;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  bounds?: google.maps.LatLngBounds;
  offset?: 'lat' | 'lng';
};

export const MIN_ZOOM_LEVEL = 2.5;
const MAX_ZOOM_LEVEL = 20;
export const DEFAULT_DEBOUNCE_TIME_MS = 50;
export const DEFAULT_SELECTED_RETAILER_ZOOM = 15;
const searchBlockMinHeight = 276;
const searchBlockInlineStart = 96;
export const PIXEL_OFFSET_X = -(SearchBlockWidth + searchBlockInlineStart) / 2;
export const PIXEL_OFFSET_Y = -(searchBlockMinHeight + 24) / 2;

export const updateMapPanBoundsZoom = (
  map: google.maps.Map | null | undefined,
  mapConfig: MapConfigType,
  debounceTimeout: number = 0,
) => {
  if (!map || !mapConfig || typeof window === 'undefined') {
    return;
  }
  debounce(debounceTimeout, () => {
    if (mapConfig.lat && mapConfig.lng) {
      const { lat, lng } = mapConfig;
      mapConfig.offset
        ? panWithOffset({ map, lat, lng, offsetDirection: mapConfig.offset })
        : map.panTo({ lat, lng });
    }

    if (mapConfig.bounds) {
      if (!mapConfig.zoom) {
        map.setZoom(MAX_ZOOM_LEVEL);
      }
      map.fitBounds(mapConfig.bounds);
      if (mapConfig.lat && mapConfig.lng) {
        mapConfig.offset
          ? offsetCenterOfMap({
              map,
              lat: mapConfig.lat,
              lng: mapConfig.lng,
              offsetDirection: mapConfig.offset,
            })
          : map.panToBounds(mapConfig.bounds);
      }
    }

    if (mapConfig.zoom || mapConfig.minZoom || mapConfig.maxZoom) {
      let toSetZoom = mapConfig.zoom ?? map.getZoom() ?? MAX_ZOOM_LEVEL;
      map.setZoom(
        clamp(
          toSetZoom,
          mapConfig.minZoom ?? MIN_ZOOM_LEVEL,
          mapConfig.maxZoom ?? MAX_ZOOM_LEVEL,
        ),
      );
    }
  })();
};

const debounce = (timeout: number, fn: (...args: any[]) => any) => {
  let timer: Timer | undefined;
  return function (this: any, ...args: any[]) {
    if (timeout <= 0) {
      //No timeout we run function direct
      fn.apply(this, args);
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
};

const clamp = (num: number, min: number, max: number) =>
  Math.max(min, Math.min(num, max));

export const roundLocation = (num: number) => {
  return parseInt(num.toPrecision(3));
};

/* 
  https://developers.google.com/maps/documentation/javascript/coordinates#pixel-coordinates
*/
export const getOffsetCenter = ({
  map,
  lat,
  lng,
  offsetDirection,
}: {
  map: google.maps.Map;
  lat: number;
  lng: number;
  offsetDirection: 'lat' | 'lng';
}) => {
  const zoom = map.getZoom();
  const projection = map.getProjection();
  if (!zoom || !projection) {
    return;
  }
  const worldCoordinateOfCenter = projection.fromLatLngToPoint({
    lat,
    lng,
  });
  if (!worldCoordinateOfCenter) {
    return;
  }

  const mapScale = Math.pow(2, zoom);
  const worldCoordinateXOffset = PIXEL_OFFSET_X / mapScale;
  const worldCoordinateYOffset = PIXEL_OFFSET_Y / mapScale;
  // Create the new point for offset
  const newCenterPoint = new google.maps.Point(
    worldCoordinateOfCenter.x +
      (offsetDirection === 'lng' ? worldCoordinateXOffset : 0),
    worldCoordinateOfCenter.y +
      (offsetDirection === 'lat' ? worldCoordinateYOffset : 0),
  );
  return projection.fromPointToLatLng(newCenterPoint);
};
const offsetCenterOfMap = ({
  map,
  lat,
  lng,
  offsetDirection,
}: {
  map: google.maps.Map;
  lat: number;
  lng: number;
  offsetDirection: 'lat' | 'lng';
}) => {
  const newCenter = getOffsetCenter({ map, lat, lng, offsetDirection });
  newCenter
    ? map.setCenter({ lat: newCenter.lat(), lng: newCenter.lng() })
    : map.setCenter({ lat, lng });
};
const panWithOffset = ({
  map,
  lat,
  lng,
  offsetDirection,
}: {
  map: google.maps.Map;
  lat: number;
  lng: number;
  offsetDirection: 'lat' | 'lng';
}) => {
  const newCenter = getOffsetCenter({ map, lat, lng, offsetDirection });
  if (newCenter) {
    map.panTo({
      lat: newCenter.lat(),
      lng: newCenter.lng(),
    });
  }
};
