import { SiteSlug } from '@volvo-cars/market-sites';
import { Retailer } from 'src/types/retailer';
import { DictionaryItemTypes } from '../content-management/DictionaryItemTypes';
import { getAvailableMarketSites } from './getAvailableMarketSites';

/**
 * Returns an object array of a retailer's available links. The links that are checked:
 *  - newCarLocator (New Inventory)
 *  - localOffers (Vehicle Offers)
 *  - usedCarLocator (Used cars locator)
 *  - workshop (Schedule Service)
 *  - partsOffer (Parts offer)
 *  - shop (New Inventory for uk)
 *  - test-drive (Test drive) Non RDM field but hardcoded for all market
 *  - quote-request (Get help with your purchase / Get a quote) Not RDM field but hardcoded for all market
 *  - url (Visit Website)
 */

type FilterKeys =
  | 'test-drive'
  | 'quote-request'
  | 'new-car-locator'
  | 'used-car-locator'
  | 'schedule-service'
  | 'shop'
  | 'visit-website'
  // TODO: In ticket 4084302, remove below after redesign (and in the rest of file)
  | 'part-offers'
  | 'local-offers';
export type RetailerLink = {
  url: string;
  translationKey: keyof DictionaryItemTypes;
  eventLabel: string;
  defaultLabel: string;
  source: LinkSource;
  configurationKey: FilterKeys;
};

enum LinkSource {
  RDM = 'RDM',
  NON_RDM = 'NON_RDM',
}

type UnfilteredRetailerLink = Omit<RetailerLink, 'url'> & {
  url: string | null | undefined;
};

type MarketConfiguration = {
  configurationKey: FilterKeys;
  disabledSiteSlugs: SiteSlug[];
};

const DISABLED_RDM_LINKS_MARKETS: SiteSlug[] = ['de-ch', 'fr-ch', 'it-ch'];
const USE_PURCHASE_HELP_MARKETS: SiteSlug[] = ['uk', 'no', 'se', 'de', 'nl']; // is flip5 se disabled quote request

const marketLinkConfigurations: MarketConfiguration[] = [
  {
    configurationKey: 'quote-request',
    disabledSiteSlugs: ['intl', 'ba', 'hr', 'us', 'il', 'si', 'se'],
  },
  {
    configurationKey: 'test-drive',
    disabledSiteSlugs: ['se', 'il', 'intl', 'us'],
  },
  {
    configurationKey: 'schedule-service',
    disabledSiteSlugs: [],
  },
  {
    configurationKey: 'new-car-locator',
    disabledSiteSlugs: ['uk'],
  },
  // Shop is corresponding to new-car-locator but in UK
  {
    configurationKey: 'shop',
    disabledSiteSlugs: getAvailableMarketSites().filter(
      (siteSlug) => siteSlug !== 'uk',
    ),
  },
  {
    configurationKey: 'used-car-locator',
    disabledSiteSlugs: ['us'],
  },
  {
    configurationKey: 'visit-website',
    disabledSiteSlugs: [],
  },
  // Remove after redesign
  {
    configurationKey: 'local-offers',
    disabledSiteSlugs: ['us'],
  },
];

export const getRetailerLinks = (
  retailer: Retailer,
  siteSlug: SiteSlug,
  redesign?: boolean,
) => {
  const {
    newCarLocator,
    usedCarLocator,
    workshop,
    testDrive,
    quoteRequest,
    shop,
    url: visitWebsite,
    // Remove after redesign
    localOffers,
    partsOffer,
  } = retailer;

  const unfilteredLinks: UnfilteredRetailerLink[] = [
    {
      url: testDrive,
      translationKey: 'global.retailer.testDrive',
      eventLabel: 'test-drive',
      defaultLabel: 'Book a test drive',
      source: LinkSource.NON_RDM,
      configurationKey: 'test-drive',
    },
    {
      url: quoteRequest,
      translationKey: USE_PURCHASE_HELP_MARKETS.includes(siteSlug)
        ? 'global.retailer.helpPurchaseRequest'
        : 'global.retailer.quoteRequest',
      eventLabel: 'quote-request',
      defaultLabel: USE_PURCHASE_HELP_MARKETS.includes(siteSlug)
        ? 'Get help with your purchase'
        : 'Get a quote',
      source: LinkSource.NON_RDM,
      configurationKey: 'quote-request',
    },
    {
      url: newCarLocator,
      translationKey: 'global.retailer.newCarLocator',
      eventLabel: 'new-car-locator',
      defaultLabel: 'New cars inventory',
      source: LinkSource.RDM,
      configurationKey: 'new-car-locator',
    },
    {
      url: shop,
      translationKey: 'global.retailer.newCarShop',
      eventLabel: 'new-car-locator',
      defaultLabel: 'View new car stock',
      source: LinkSource.NON_RDM,
      configurationKey: 'shop',
    },
    {
      url: usedCarLocator,
      translationKey: 'global.retailer.usedCarLocator',
      eventLabel: 'used-car-locator',
      defaultLabel: 'Used cars inventory',
      source: LinkSource.RDM,
      configurationKey: 'used-car-locator',
    },
    {
      url: workshop,
      translationKey: 'global.retailer.scheduleService',
      eventLabel: 'schedule-service',
      defaultLabel: 'Schedule a service',
      source: LinkSource.RDM,
      configurationKey: 'schedule-service',
    },
    {
      url: visitWebsite,
      translationKey: 'global.retailer.visitWebsite',
      eventLabel: 'visit website',
      defaultLabel: 'Visit website',
      source: LinkSource.RDM,
      configurationKey: 'visit-website',
    },
    // Remove after redesign
    {
      url: partsOffer,
      translationKey: 'global.retailer.partsOffer',
      eventLabel: 'parts-offer',
      defaultLabel: 'Parts offer',
      source: LinkSource.RDM,
      configurationKey: 'part-offers',
    },
    {
      url: localOffers,
      translationKey: 'global.retailer.localOffers',
      eventLabel: 'local-offers',
      defaultLabel: 'Vehicle offers',
      source: LinkSource.RDM,
      configurationKey: 'local-offers',
    },
  ];

  return unfilteredLinks
    .filter((l): l is RetailerLink => !!l.url)
    .filter(
      (l) =>
        !DISABLED_RDM_LINKS_MARKETS.includes(siteSlug) ||
        l.source === LinkSource.NON_RDM,
    )
    .filter((l) => {
      return !marketLinkConfigurations.find((config) => {
        return (
          l.configurationKey === config.configurationKey &&
          config.disabledSiteSlugs.includes(siteSlug)
        );
      });
    })
    .filter((l) => {
      return redesign || l.configurationKey !== 'visit-website';
    })
    .filter((l) => {
      return (
        !redesign ||
        (l.configurationKey !== 'part-offers' &&
          l.configurationKey !== 'local-offers')
      );
    });
};
