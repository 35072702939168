type GetAddressLinkGoogleMapsProps = {
  coordinatesMapLink: boolean;
  name: string;
  addressLine1: string;
  addressLine2: string;
  longitude: string;
  latitude: string;
};
export function getMapLinkQuery({
  coordinatesMapLink,
  name,
  addressLine1,
  addressLine2,
  longitude = '',
  latitude = '',
}: GetAddressLinkGoogleMapsProps) {
  const query = coordinatesMapLink
    ? `${latitude},${longitude}`
    : `${name}, ${addressLine1 || ''} ${addressLine2 || ''}`;

  // using replace instead of replaceAll due to browser compatibility
  return query.replace(/&/g, '%26');
}
