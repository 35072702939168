import styles from './SearchFrame.module.css';

type SearchFrameProps = React.PropsWithChildren<{
  classNames?: string;
}>;

const SearchFrame = ({ children, classNames }: SearchFrameProps) => {
  return (
    <div
      className={`${styles.search} ${classNames} dl-max-h-inherit bg-secondary p-24 rounded-md flex-col h-full gap-24 dl-z-1 sticky dl-top-topbar-h`}
    >
      {children}
    </div>
  );
};

export default SearchFrame;
