import React from 'react';
import { Icon } from '@volvo-cars/react-icons';

type MapControlsProps = { map: google.maps.Map };
export const MapControls = ({ map }: MapControlsProps): JSX.Element | null => {
  if (!map) return null;
  return <Controls map={map} />;
};

type ControlsProps = { map: google.maps.Map };
const Controls = ({ map }: ControlsProps): JSX.Element => {
  return (
    <div className="m-16 flex-col bg-primary items-center rounded until-lg:hidden">
      <button
        type="button"
        className="p-8"
        onClick={() => {
          const zoom = map?.getZoom();
          if (typeof zoom !== 'undefined') {
            map?.setZoom(zoom + 1);
          }
        }}
      >
        <Icon icon="plus" size={16} />
      </button>
      <hr className="border-t m-0 border-ornament w-16" />
      <button
        type="button"
        className="p-8"
        onClick={() => {
          const zoom = map?.getZoom();
          if (typeof zoom !== 'undefined') {
            map?.setZoom(zoom - 1);
          }
        }}
      >
        <Icon icon="minus" size={16} />
      </button>
    </div>
  );
};
