import { DictionaryItemTypes } from 'src/content-management/DictionaryItemTypes';
import { Capability } from 'src/types/retailerCapabilities';
import { ErrorMessages } from 'src/constants/errorMessages';
import { logError } from './logError';

export const translateCapability = (
  capability: Capability,
  translate: (itemKey: keyof DictionaryItemTypes) => string,
) => {
  let exhaustiveCheck: never;
  switch (capability) {
    case 'service':
      return translate('global.retailer.serviceCapability') || 'Service';
    case 'used car sales':
      return (
        translate('global.retailer.usedCarSalesCapability') || 'Used car sales'
      );
    case 'sales':
      //4084302 When all translations are done, this should change to salesCapability
      return translate('global.retailer.retailerCapability') || 'Sales';
    case 'collision center':
      return (
        translate('global.retailer.collisionCenterCapability') ||
        'Collision center'
      );
    case 'selekt':
      return translate('global.retailer.selektCapability') || 'Selekt';
    case 'diplomat':
      return translate('global.retailer.diplomatCapability') || 'Diplomat';
    case 'test drive':
      return translate('global.retailer.testDriveCapability') || 'Test drive';
    default:
      // Produces type error if Capability is not added in switch
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      exhaustiveCheck = capability;
      logError(ErrorMessages.INVALID_CAPABILITY);
      return '';
  }
};
