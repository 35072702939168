import {
  topbarHeightFromM,
  topbarHeightUntilM,
} from '@volvo-cars/site-nav-embed/src/constants';
import { useBreakpoints } from './useBreakpoints';
/**
 * Returns topbar height to be used in JS. When using CSS,
 * the variable `--sitenav-topbar-height` should be used instead
 */
export const useTopbarHeight = () => {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilM } = useBreakpoints();
  return untilM ? topbarHeightUntilM : topbarHeightFromM;
};
