import React from 'react';
type RetailerNameDistanceProps = {
  name: string;
  distance?: string;
};
export const RetailerNameDistance = ({
  name,
  distance,
}: RetailerNameDistanceProps) => {
  return (
    <div className="flex-row justify-between font-medium">
      <p>{name}</p>
      {distance && <p className="min-w-fit">{distance}</p>}
    </div>
  );
};
