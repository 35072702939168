export function createStyleSheet(name: string) {
  const sheet = document.createElement('style');
  sheet.dataset.name = name;
  return sheet;
}

export function getStyleSheet(name: string) {
  return document.querySelector(`style[data-name="${name}"]`);
}

export function removeStylesheet(element: Element) {
  try {
    element?.remove();
  } catch (error) {
    console.error(error);
  }
}
