'use client';
import { useEffect } from 'react';
import {
  createStyleSheet,
  getStyleSheet,
  removeStylesheet,
} from './createStyleSheet';

const breakpoint = {
  /** \< 480 (identical to untilM) */
  onlyS: '@media (max-width: 479px)',
  /** \< 480 */
  untilM: '@media (max-width: 479px)',
  /** \> 480 */
  fromM: '@media (min-width: 480px)',
  /** \>= 480 && < 1024 */
  onlyM: '@media (min-width: 480px) and (max-width: 1023px)',
  /** \< 1024 */
  untilL: '@media (max-width: 1023px)',
  /** \>= 1024 */
  fromL: '@media (min-width: 1024px)',
  /** \>= 1024 && < 1600 */
  onlyL: '@media (min-width: 1024px) and (max-width: 1599px)',
  /** \< 1600 */
  untilXL: '@media (max-width: 1599px)',
  /** \>= 1600 */
  fromXL: '@media (min-width: 1600px)',
  /** \>= 1600 (identical to fromXL) */
  onlyXL: '@media (min-width: 1600px)',
};

const styleSheetName = 'sn-chat-button-offset';

export const useChatButtonOffset = (
  offset: string,
  disable = false,
  breakpointName?: keyof typeof breakpoint,
) => {
  useEffect(() => {
    if (disable) return;

    const styleSheet =
      getStyleSheet(styleSheetName) || createStyleSheet(styleSheetName);

    if (breakpointName) {
      const media = breakpoint[breakpointName];
      styleSheet.innerHTML = `
        ${media} {
          :root {
            --chat-button-bottom-offset: ${offset}
          }
        }
        `;
    } else {
      styleSheet.innerHTML = `
          :root {
            --chat-button-bottom-offset: ${offset}
          }
        `;
    }

    document.head.appendChild(styleSheet);

    return () => {
      removeStylesheet(styleSheet);
    };
  }, [offset, breakpointName, disable]);
};
