import { SiteSlug } from '@volvo-cars/market-sites';
import * as seoKeywordsJSON from './seoKeywords.json';

export const seoKeywords = seoKeywordsJSON as Record<SiteSlug, string>;

/* TODO 4084302: To be removed in ticket when all markets are live with redesign flow */
/* Note: When altering these, alter the corresponding item in next.config.js as well */

const excludedRedesignSiteSlugs = ['se', 'us'];
export const redesignSiteSlugs: SiteSlug[] = (
  Object.keys(seoKeywords) as SiteSlug[]
).filter((key) => !excludedRedesignSiteSlugs.includes(key));
