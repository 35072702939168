import { useCurrentMarketSite } from '@vcc-www/market-sites';
import {
  SelectionCapabilities,
  useCapabilitySelection,
} from 'src/hooks/useCapabilitySelection';
import { Retailer } from 'src/types/retailer';
import { useTranslate } from 'src/providers';
import { translateCapability } from 'src/utils/translateCapability';
import { CapabilityTabs } from '../CapabilityTabs';
import { PhoneNumber } from '../PhoneNumber';
import { OpeningHours } from '../../redesign/OpeningHours';

type CapabilitySelectionProps = {
  openingHours: Retailer['openingHours'];
  phoneNumbers: Retailer['phoneNumbers'];
  capabilities: Retailer['capabilities'];
  name: Retailer['name'];
};

const CapabilitySelection = ({
  openingHours,
  phoneNumbers,
  capabilities,
  name,
}: CapabilitySelectionProps) => {
  const translate = useTranslate();
  const { locale } = useCurrentMarketSite();

  const defaultCapabilityIndex = 0;
  const tabCapabilities: SelectionCapabilities[] = ['sales', 'service'];
  const selectionCapabilites = tabCapabilities.filter((c) =>
    capabilities.includes(c),
  );
  const {
    setSelectedCapability,
    selectedOpeningHours,
    selectedPhoneNumber,
    selectedCapability,
  } = useCapabilitySelection({
    openingHours,
    phoneNumbers,
    defaultCapability: selectionCapabilites.at(defaultCapabilityIndex),
  });
  return selectionCapabilites.length > 0 ? (
    <>
      {selectionCapabilites.length > 1 && (
        <div className="flex justify-center">
          <CapabilityTabs
            capabilities={selectionCapabilites}
            defaultSelectedIndex={defaultCapabilityIndex}
            onCapabilityChanged={setSelectedCapability}
          />
        </div>
      )}
      {selectedCapability && (selectedOpeningHours || selectedPhoneNumber) && (
        <>
          <div className="flex justify-between font-medium">
            <p>{translateCapability(selectedCapability, translate)}</p>

            <PhoneNumber
              name={name}
              phoneNumber={selectedPhoneNumber}
              textColour="black"
            />
          </div>
          {selectedOpeningHours && (
            <OpeningHours openingHours={selectedOpeningHours} locale={locale} />
          )}
        </>
      )}
    </>
  ) : (
    <PhoneNumber
      name={name}
      phoneNumber={phoneNumbers.main}
      textColour="black"
    />
  );
};

export default CapabilitySelection;
