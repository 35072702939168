import React, { ErrorInfo } from 'react';
import { logError } from 'src/utils/logError';
import { ErrorMessages } from 'src/constants/errorMessages';

export default class MapErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(
      `${ErrorMessages.MAP_UI_ERROR}:  ${error} ${errorInfo.componentStack ? `with component trace: ${errorInfo.componentStack}` : ''} `,
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="px-8 my-24 bg-secondary flex-col justify-center items-center w-full" />
      );
    } else {
      return this.props.children;
    }
  }
}
