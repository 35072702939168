import { Retailer } from 'src/types/retailer';
import CardContent from './CardContent';
import CardHeader from './CardHeader';

type ExpandedCardProps = {
  onClose: () => void;
  retailer: Retailer;
};

const ExpandedCard = ({ onClose, retailer }: ExpandedCardProps) => {
  return (
    <div className="scrollbar-none pb-24 -mb-24 dl-max-h-inherit overflow-auto flex-col">
      <div className="flex justify-between bg-always-white rounded-t p-24 sticky top-0">
        <CardHeader onClose={onClose} />
      </div>
      <div className="bg-always-white rounded-b px-24 pb-24 md:mb-24 lg:mb-0">
        <CardContent retailer={retailer} />
      </div>
    </div>
  );
};

export default ExpandedCard;
