import React from 'react';
import Link from 'next/link';
import { Track } from '@volvo-cars/tracking';
import { TRACKING_CATEGORY } from 'src/constants/tracking';
import { useTranslate } from 'src/hooks';

type VisitRetailerLinkProps = {
  name: string;
  url: string;
};

export const VisitRetailerLink = ({ name, url }: VisitRetailerLinkProps) => {
  const translate = useTranslate();
  return (
    <Track
      eventLabel="visit website"
      eventAction="link|click"
      event="custom_event"
      customData={{ eventCategory: TRACKING_CATEGORY }}
    >
      <Link
        className="button-filled"
        data-testid="dealer:visitWebsite"
        target="_blank"
        href={url}
        aria-label={
          translate('global.retailer.visitWebsiteAriaLabel', {
            retailerName: name,
          }) || `Visit website ${name}`
        }
      >
        {translate('global.retailer.visitWebsite') || 'Visit website'}
      </Link>
    </Track>
  );
};
