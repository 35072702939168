import { SiteSlug } from '@volvo-cars/market-sites';
import { SelectorQueryParams, UseCase } from 'src/types/selectorTypes';

export const REQUIRED_SELECTOR_QUERY_PARAMS: Array<keyof SelectorQueryParams> =
  ['redirect'];

export const ENABLED_SELECTOR_MARKET_SITES: SiteSlug[] = [
  'en-ca',
  'es-us',
  'fr-ca',
  'uk',
  'us',
  'dk',
  'nl',
  'fi',
  'fr-ch',
  'de-ch',
  'it-ch',
  'de',
  'no',
  'pl',
  'cz',
  'br',
  'gr',
  'ie',
];

export const SELECTOR_USECASES: Partial<Record<SiteSlug, Array<UseCase>>> = {
  'en-ca': ['preorder'],
  'es-us': ['preorder', 'reservar'],
  'fr-ca': ['pre-commande'],
  us: ['preorder'],
  dk: ['inventory'],
  nl: ['inventory'],
  fi: ['inventory'],
  'fr-ch': ['inventory'],
  'de-ch': ['inventory'],
  'it-ch': ['inventory'],
  de: ['inventory'],
  no: ['inventory'],
  pl: ['inventory'],
  cz: ['inventory'],
  br: ['inventory'],
  gr: ['inventory'],
  ie: ['inventory'],
};
